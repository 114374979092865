export enum Types {
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  GET_IMAGES = 'GET_IMAGES',
  SAVE_SECTION = 'SAVE_SECTION',
  GET_SECTION = 'GET_SECTION',
  UPLOAD_IMAGE_URL = 'UPLOAD_IMAGE_URL',
  CREATE_APP = 'CREATE_APP',
  GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN',
  CREATE_LDP = 'CREATE_LDP',
  GET_DATA_LDP = 'GET_DATA_LDP',
  SAVE_LDP = 'SAVE_LDP',
  GET_ALL_LDP = 'GET_ALL_LDP',
  NEW_LDP_NAME = 'NEW_LDP_NAME'
 }
