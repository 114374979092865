import {
  ButtonProps,
  HeadingProps,
  LineProps,
  SectionProps,
  ParagraphProps,
  ListProps,
  VideoProps,
  ImageProps,
  FormProps,
  ShapeProps,
  BoxProps,
  CodeHtmlProps,
  GroupProps,
  CaroselProps,
  GalleryProps,
  CountdownProps,
  LuckySpinProps,
} from './types/templates'

const button: ButtonProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'button',
  text: 'MUA NGAY',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(244, 64, 37)',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textDecorationLine: 'none',
  textTransform: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1',
  letterSpacing: 0,
  borderRadius: {
    borderStyle: 'none',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  overlayColor: 'none',
  typeOverlay: 'color',
  position: 0,
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  className: '',
  classStyle: '',
  link: {
    url: '',
    target: '_self',
  },
  call: '',
  mail: '',
  changeSection: '',
  clickEventType: 'none',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  lock: false,
  desktop: {
    top: 200,
    left: 200,
    width: 160,
    height: 40,
    fontSize: 16,
    textAlign: 'center',
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 160,
    height: 40,
    fontSize: 16,
    textAlign: 'center',
    hide: false,
  },
}

const heading: HeadingProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'heading',
  text: 'Headline',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(0, 0, 0)',
  tag: 'h3',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textDecorationLine: 'none',
  textTransform: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1',
  letterSpacing: 0,
  clickEventType: 'none',
  textShadow: 'none',
  className: '',
  classStyle: '',
  link: {
    url: '',
    target: '_self',
  },
  call: '',
  mail: '',
  changeSection: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 500,
    left: 500,
    width: 150,
    fontSize: 16,
    height: 24,
    textAlign: 'left',
    hide: false,
  },
  mobile: {
    top: 500,
    left: 500,
    width: 150,
    fontSize: 16,
    height: 24,
    textAlign: 'left',
    hide: false,
  },
}

const line: LineProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'line',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'rgb(0, 0, 0)',
  direction: 'horizon',
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 400,
    left: 400,
    width: 300,
    height: 18,
    hide: false,
  },
  mobile: {
    top: 400,
    left: 400,
    width: 300,
    height: 18,
    hide: false,
  },
}

const paragraph: ParagraphProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'paragraph',
  text: 'Paragraph',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(0, 0, 0)',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textDecorationLine: 'none',
  textTransform: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1',
  letterSpacing: 0,
  clickEventType: 'none',
  textShadow: 'none',
  className: '',
  classStyle: '',
  link: {
    url: '',
    target: '_self',
  },
  call: '',
  mail: '',
  changeSection: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 450,
    left: 450,
    width: 150,
    fontSize: 16,
    height: 24,
    textAlign: 'left',
    hide: false,
  },
  mobile: {
    top: 450,
    left: 450,
    width: 150,
    fontSize: 16,
    height: 24,
    textAlign: 'left',
    hide: false,
  },
}

const list: ListProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'list',
  text: '<ul><li>List 1</li> <li>List 2</li> <li>List 3</li></ul>',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(0, 0, 0)',
  textShadow: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1.5',
  letterSpacing: 0,
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 360,
    left: 360,
    width: 150,
    fontSize: 16,
    height: 72,
    textAlign: 'left',
    hide: false,
  },
  mobile: {
    top: 450,
    left: 450,
    width: 150,
    fontSize: 16,
    height: 72,
    textAlign: 'left',
    hide: false,
  },
}

const video: VideoProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'video',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  videoId: 'tlPu5WL2YxA',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 360,
    left: 360,
    width: 320,
    height: 180,
    hide: false,
  },
  mobile: {
    top: 450,
    left: 450,
    width: 320,
    height: 180,
    hide: false,
  },
}

const image: ImageProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'image',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  crop: {
    top: 0,
    left: 0,
    width: 320,
    height: 180,
    crop: false,
  },
  url: '',
  background: '',
  overlayColor: 'none',
  typeOverlay: 'color',
  clickEventType: 'none',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  className: '',
  classStyle: '',
  borderRadius: {
    borderStyle: 'none',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 360,
    left: 360,
    width: 320,
    height: 180,
    hide: false,
  },
  mobile: {
    top: 450,
    left: 450,
    width: 320,
    height: 180,
    hide: false,
  },
}

const section: SectionProps = {
  id: '',
  type: 'section',
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  backgroundSize: 'cover',
  backgroundAttachment: 'scroll',
  backgroundOrigin: 'content-box',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundOpacity: 100,
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  overlayColor: 'none',
  typeOverlay: 'color',
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    height: 800,
  },
  mobile: {
    height: 800,
  },
  beauty: false,
}

const form: FormProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'form',
  zIndex: 1,
  url: '',
  color: 'rgb(0, 0, 0)',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textDecorationLine: 'none',
  textTransform: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1',
  letterSpacing: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  desktop: {
    top: 360,
    left: 360,
    width: 300,
    height: 200,
    hide: false,
    fontSize: 16,
  },
  mobile: {
    top: 450,
    left: 450,
    width: 300,
    height: 200,
    fontSize: 16,
    hide: false,
  },
}

const shape: ShapeProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'shape',
  icon: '500px',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(0, 0, 0)',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  link: {
    url: '',
    target: '_self',
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  clickEventType: 'none',
  className: '',
  classStyle: '',
  desktop: {
    top: 200,
    left: 200,
    width: 100,
    height: 100,
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 100,
    height: 100,
    hide: false,
  },
}

const box: BoxProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'box',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  backgroundColor: '#E9E9E9',
  backgroundImage: '',
  backgroundSize: 'cover',
  backgroundAttachment: 'scroll',
  backgroundOrigin: 'content-box',
  backgroundPosition: 'left top',
  backgroundRepeat: 'no-repeat',
  backgroundOpacity: 100,
  overlayColor: 'none',
  typeOverlay: 'color',
  borderRadius: {
    borderStyle: 'none',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  link: {
    url: '',
    target: '_self',
  },
  call: '',
  mail: '',
  changeSection: '',
  className: '',
  classStyle: '',
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  clickEventType: 'none',
  desktop: {
    top: 200,
    left: 200,
    width: 160,
    height: 160,
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 160,
    height: 160,
    hide: false,
  },
}

const codeHtml: CodeHtmlProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'codeHtml',
  code: '',
  zIndex: 1,
  className: '',
  classStyle: '',
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 200,
    left: 200,
    width: 200,
    height: 160,
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 200,
    height: 160,
    hide: false,
  },
}

const group: GroupProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'group',
  zIndex: 1,
  element: [],
  className: '',
  classStyle: '',
  desktop: {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    hide: false,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    hide: false,
  },
}

const carosel: CaroselProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'carosel',
  zIndex: 1,
  widthElement: 420,
  timeScroll: 5,
  elements: {
    number: 3,
    data: [],
  },
  className: '',
  classStyle: '',
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 200,
    left: 200,
    width: 420,
    height: 250,
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 420,
    height: 250,
    hide: false,
  },
}

const gallery: GalleryProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'gallery',
  zIndex: 1,
  timeScroll: 5,
  images: [],
  className: '',
  classStyle: '',
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  thumb: {
    show: true,
    position: 'bottom',
    width: 80,
    height: 80,
    marginGallery: 5,
    marginThumb: 5,
  },
  desktop: {
    top: 200,
    left: 200,
    width: 418,
    height: 448,
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 418,
    height: 448,
    hide: false,
  },
}

const countdown: CountdownProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'countdown',
  timeCount: 43200,
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  color: 'rgb(0, 0, 0)',
  backgroundColor: 'rgb(255, 255, 255, 0)',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textDecorationLine: 'none',
  textTransform: 'none',
  padding: 0,
  fontFamily: '',
  lineHeight: '1',
  letterSpacing: 0,
  borderRadius: {
    borderStyle: 'none',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  overlayColor: 'none',
  typeOverlay: 'color',
  position: 0,
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  className: '',
  classStyle: '',
  countdown: {
    showDate: true,
    showHour: true,
    showMinute: true,
    showSecond: true,
    type: 'minute',
    minute: 720,
    start: '00:00:00',
    stop: '24:00:00',
    end: '2021-01-01 00:00:00',
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  lock: false,
  desktop: {
    top: 200,
    left: 200,
    width: 300,
    height: 80,
    fontSize: 40,
    textAlign: 'center',
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 300,
    height: 80,
    fontSize: 40,
    textAlign: 'center',
    hide: false,
  },
}

const luckySpin: LuckySpinProps = {
  id: '',
  sectionId: '',
  groupId: '',
  caroselId: '',
  type: 'luckySpin',
  listPrize: `COUPON50|Coupon 50k|90%
  SAMSUNG|Samsung Galaxy S20|0%
  COUPON1000|Coupon 1000k|2%
  COUPON500|Coupon 500k|3%
  COUPON100|Coupon 100k|5%
  IPHONE|Iphone 11 Pro Max|0%`,
  message: 'Chúc mừng bạn nhận được {{coupon_text}}. Nhập mã: {{coupon_code}} để sử dụng. Bạn còn {{spin_turn_left}} lượt quay.',
  turn: 1,
  background: 'https://w.ladicdn.com/source/illustration/spin-lucky-04.svg',
  rotateBackground: 0,
  button: 'https://w.ladicdn.com/source/spin-btn1.svg',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textDecorationLine: 'none',
  textTransform: 'none',
  fontFamily: '',
  padding: 0,
  lineHeight: '1',
  letterSpacing: 0,
  lock: false,
  desktop: {
    top: 200,
    left: 200,
    width: 400,
    height: 400,
    fontSize: 15,
    textAlign: 'center',
    hide: false,
  },
  mobile: {
    top: 200,
    left: 200,
    width: 400,
    height: 400,
    fontSize: 15,
    textAlign: 'center',
    hide: false,
  },
}

export default {
  button,
  heading,
  line,
  section,
  paragraph,
  list,
  video,
  image,
  form,
  shape,
  box,
  codeHtml,
  group,
  carosel,
  gallery,
  countdown,
  luckySpin,
}
