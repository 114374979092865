import CodeHtml from './CodeHtml'
import Images from './Images'
import Sections from './Sections'
import Shapes from './Shapes'

export default {
  CodeHtml,
  Images,
  Shapes,
  Sections,
}
