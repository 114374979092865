import {
  InputProps,
  CheckboxProps,
  RadioProps,
  ButtonProps,
  SelectProps,
  SelectAddressProps,
} from './types/formTemplates'

const inputName: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'text',
  placeholder: 'Họ và tên',
  tag: 'input',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  dataName: 'name',
  defaultValue: '',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputAddress: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'text',
  placeholder: 'Địa chỉ',
  tag: 'input',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  dataName: 'address',
  defaultValue: '',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputDate: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'text',
  placeholder: 'Ngày tháng',
  tag: 'input',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  dataName: 'date',
  defaultValue: '',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputNumber: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'number',
  placeholder: 'Số lượng',
  tag: 'input',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  dataName: 'number',
  defaultValue: '',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputEmail: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'email',
  placeholder: 'Email',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  tag: 'input',
  boxShadow: 'none',
  dataName: 'email',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  desktop: {
    top: 40,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 40,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputCode: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'text',
  placeholder: 'Mã giảm giá',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  tag: 'input',
  boxShadow: 'none',
  dataName: 'code',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  desktop: {
    top: 80,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 80,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputPhoneNumber: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'number',
  placeholder: 'Số điện thoại',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  tag: 'input',
  boxShadow: 'none',
  dataName: 'phone',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  desktop: {
    top: 80,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 80,
    left: 0,
    width: 300,
    height: 35,
  },
}

const inputTextArea: InputProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'textArea',
  placeholder: 'Mô tả',
  zIndex: 1,
  color: 'rgb(0, 0, 0)',
  fontSize: 16,
  tag: 'input',
  dataName: 'text',
  defaultValue: '',
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  desktop: {
    top: 120,
    left: 0,
    width: 300,
    height: 70,
  },
  mobile: {
    top: 120,
    left: 0,
    width: 300,
    height: 70,
  },
}

const button: ButtonProps = {
  id: '',
  formId: '',
  sectionId: '',
  text: 'Xác nhận',
  tag: 'button',
  type: 'buttonForm',
  color: 'rgb(255, 255, 255)',
  overlayColor: 'none',
  typeOverlay: 'color',
  zIndex: 1,
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  boxShadow: 'none',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(244, 64, 37)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 70,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 70,
  },
}

const radio: RadioProps = {
  id: '',
  formId: '',
  sectionId: '',
  zIndex: 1,
  type: 'radio',
  dataName: 'radio',
  boxShadow: 'none',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  values: 'Option 1\nOption 2\nOption 3',
  tag: 'radio',
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 100,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 100,
  },
}

const select: SelectProps = {
  id: '',
  formId: '',
  sectionId: '',
  zIndex: 1,
  type: 'select',
  dataName: 'select',
  boxShadow: 'none',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  values: 'Option 1\nOption 2\nOption 3',
  tag: 'select',
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 35,
  },
}

const selectAddress: SelectAddressProps = {
  id: '',
  formId: '',
  sectionId: '',
  zIndex: 1,
  type: 'selectAddress',
  dataName: 'selectAddress',
  boxShadow: 'none',
  defaultValueDistrict: '',
  defaultValueCommune: '',
  defaultValueProvince: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 0,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  tag: 'selectAddress',
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 80,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 80,
  },
}

const checkbox: CheckboxProps = {
  id: '',
  formId: '',
  sectionId: '',
  type: 'checkbox',
  zIndex: 1,
  boxShadow: 'none',
  dataName: 'checkbox',
  defaultValue: '',
  boxShadowWidth: 0,
  boxShadowHeight: 0,
  boxShadowOpacity: 25,
  boxShadowDark: 0,
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundImage: '',
  typeBackground: 'color',
  typeGradient: 'linear-gradient',
  position: 0,
  filter: {
    contrast: 100,
    brightness: 100,
    saturate: 100,
    sepia: 0,
    grayscale: 0,
    invert: 0,
    hueRotate: 0,
    blur: 0,
  },
  transform: {
    opacity: 100,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    skewX: 0,
    skewY: 0,
    perspective: 1000,
  },
  borderRadius: {
    borderStyle: 'none',
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    borderAll: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  values: 'Option 1\nOption 2\nOption 3',
  tag: 'checkbox',
  desktop: {
    top: 0,
    left: 0,
    width: 300,
    height: 100,
  },
  mobile: {
    top: 0,
    left: 0,
    width: 300,
    height: 100,
  },
}

export default {
  inputName, inputEmail, inputNumber, inputPhoneNumber, inputAddress, inputDate, inputTextArea, radio, checkbox, button, inputCode, select, selectAddress,
}
