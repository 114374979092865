export enum Types {
  DRAG_ELEMENT = 'DRAG_ELEMENT',
  RESIZE_ELEMENT = 'RESIZE_ELEMENT',
  GROUP_DRAG = 'GROUP_DRAG',
  GROUP_RESIZE = 'GROUP_RESIZE',
  CROP_ELEMENT = 'CROP_ELEMENT',
  FORM_RESIZE = 'FORM_RESIZE',
  SNAP_ELEMENT = 'SNAP_ELEMENT',
  EDIT_CAROSEL = 'EDIT_CAROSEL',
  NUMBER_CAROSEL = 'NUMBER_CAROSEL',
}
